import React, { Component } from 'react';

class Portfolio extends Component {
  render() {
    return (
      <section id="portfolio">
        <div className="row projects">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Works.</h1>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                  <a href="https://github.com/arpansapkota" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="images/thumbs/01.jpg" alt="Tiger Re-Identification" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Re-Identification of Tigers using Computer Vision</h3>
                      <p>A desktop application for identifying tigers and counting their population using Python. Re-Identification using Deep Neural Networks like Convolutional Neural Network, PPGNet, ResNet.</p>
                    </div>
                    <ul className="actions">
                      <div className="actions-wrapper">
                        <li><a href="#" className="button project-button" target="_blank" rel="noopener noreferrer">View Demo</a></li>
                        <li><a href="https://github.com/arpansapkota" className="button project-button brands" target="_blank" rel="noopener noreferrer"><i className="fa fa-github icon"></i>View Source</a></li>
                      </div>
                      <div className="languages">
                        <i className="devicon-python-plain-wordmark technology"></i>
                      </div>
                    </ul>
                  </div>
                </article>
              </div>
              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                  <a href="https://github.com/arpansapkota" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="images/thumbs/02.jpg" alt="Agro Mining" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Agro Mining Web App</h3>
                      <p>An ecommerce web app for agricultural products using Django 1.9, Time Series Analysis of the products from Kaggle.com Using ARIMA model.</p>
                    </div>
                    <ul className="actions">
                      <div className="actions-wrapper">
                        <li><a href="#" className="button project-button" target="_blank" rel="noopener noreferrer">View Demo</a></li>
                        <li><a href="https://github.com/arpansapkota" className="button project-button brands" target="_blank" rel="noopener noreferrer"><i className="fa fa-github icon"></i>View Source</a></li>
                      </div>
                      <div className="languages">
                        <i className="devicon-python-plain-wordmark technology"></i>
                        <i className="devicon-django-plain-wordmark technology"></i>
                      </div>
                    </ul>
                  </div>
                </article>
              </div>
              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                  <a href="https://github.com/arpansapkota" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="images/thumbs/03.png" alt="Grocery Inventory System" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>Grocery Inventory System Terminal App</h3>
                      <p>A Simple terminal application made with the Object Oriented Programming Language for gaining the practical knowledge of OOP features like Class, Objects, Inheritance, etc.</p>
                    </div>
                    <ul className="actions">
                      <div className="actions-wrapper">
                        <li><a href="#" className="button project-button" target="_blank" rel="noopener noreferrer">View Demo</a></li>
                        <li><a href="https://github.com/arpansapkota" className="button project-button brands" target="_blank" rel="noopener noreferrer"><i className="fa fa-github icon"></i>View Source</a></li>
                      </div>
                      <div className="languages">
                        <i className="devicon-cplusplus-plain-wordmark technology"></i>
                      </div>
                    </ul>
                  </div>
                </article>
              </div>
              <div className="article-wrapper">
                <article className="col-12 col-12-xsmall work-item">
                  <a href="https://github.com/arpansapkota" className="image fit thumb" target="_blank" rel="noopener noreferrer"><img src="images/thumbs/04.jpg" alt="IP Surveillance System" /></a>
                  <div className="art-description">
                    <div className="text-wrapper">
                      <h3>IP Surveillance System</h3>
                      <p>Desktop Application for obtaining the IP Address of the computer user. Along with IP Address, Picture captured from Cam is sent in mail, So it will identify the person who is using the computer.</p>
                    </div>
                    <ul className="actions">
                      <div className="actions-wrapper">
                        <li><a href="#" className="button project-button" target="_blank" rel="noopener noreferrer">View Demo</a></li>
                        <li><a href="https://github.com/arpansapkota" className="button project-button brands" target="_blank" rel="noopener noreferrer"><i className="fa fa-github icon"></i>View Source</a></li>
                      </div>
                      <div className="languages">
                        <i className="devicon-python-plain-wordmark technology"></i>
                      </div>
                    </ul>
                  </div>
                </article>
              </div>
              <ul className="actions">
                <li className="wide-button"><a href="https://github.com/arpansapkota" className="button portfolio-button" target="_blank" rel="noopener noreferrer">Full Portfolio</a></li>
              </ul>
            </div>
          </div>
        </div>
   </section>
    );
  }
}

export default Portfolio;
